export function formatUTCDate(date: Date | string): Date {
  if (!date) return null;

  // Se è una stringa, convertiamola in Date
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  // Creiamo una nuova data UTC usando i componenti UTC della data originale
  return new Date(Date.UTC(
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
    dateObj.getUTCDate(),
    dateObj.getUTCHours(),
    dateObj.getUTCMinutes(),
    dateObj.getUTCSeconds()
  ));
}
